//* .\@\App.js
//* 
//* Script del componente principal ./@/App.vue. 
//*
//* Desarrollado por Juan Carlos Jocop.
//* Agosto, 2021

import menuTop          from '@/app_navbar.vue'

import iconosWyC        from '@/components/iconosWyC.vue'

import redessociales    from '@/views/Redessociales.vue'

import store from './store'

import { mapGetters } from 'vuex'

import Vue from 'vue'
import VTitle from 'v-title';
Vue.use(VTitle);

export default {

    store,
    name: 'app',
    components: {
        menuTop,

        iconosWyC,     
        
        redessociales,
    },
    computed: {
        // ...mapGetters(['laCarretilla']),
    },
    methods: {    
    },
}