//* ./@/views/Home.js
//*
//* Script del componente ./@/views/Home.vue.
//*
//* Desarrollado por Juan Carlos Jocop
//* Noviembre, 2022

import Vue from 'vue'

import Swal from 'sweetalert2'

import { mapGetters } from 'vuex'

export default {
  name: 'servicios',

  data () {
    return {
      bAplicar:false,

      espejoIR: '',
      iniciando: false,
      imagen: null,
      imagenHTML: 'icono.png',

      espejoBR: '',
      iniciandoB: false,
      broshure: null,
      broshureHTML: 'icono.png',

      seleccionado: {
        nombre: '',
        descripcion: '',
        vigencia: new Date(),
        fregistro: new Date(),
      },

      aplicacionRegistro: {
        nombre: '',
        whatsApp: '',
        email: '',
        estatus: 'APLICANDO',

        imagen: '',
        imagenRuta: '',
        broshure: '',
        broshureRuta: '',

        senalado: '',
      }      
    } 
  },
  computed:{
    ...mapGetters(['losServiciosGen','losServiciosEsp']), 
    filtrar(){

      let i= 0;

			return this.losServiciosGen
          .filter((registro) => {
        

          if (i/2 === parseInt(i/2)){
            registro.color= 'white animated fadeInUp';
            registro.transicion= 'fadeInUp'
          } else{
            registro.color= 'teal lighten-4 animated fadeInDown';
            registro.transicion= 'fadeInDown'
          }

          i= i + 1;

          return registro;
      })
    },
    filtrarEsp(){

      let i= 0;

			return this.losServiciosEsp
          .filter((registro) => {
        

          if (i/2 === parseInt(i/2)){
            registro.color= 'teal lighten-3 ';
            registro.transicion= ''
          } else{
            registro.color= 'teal lighten-4 animated zoomIn';
            registro.transicion= 'zoomIn'
          }

          i= i + 1;

          return registro;
      })
    },
    datosIncompletos(){
      return !this.aplicacionRegistro.nombre || !this.aplicacionRegistro.whatsApp || 
              !this.aplicacionRegistro.email
    }
  },  
  created(){

    //? Carga los Servicios Generales
    this.cargueServiciosGen();
    this.cargueServiciosEsp();
  },
  mounted(){
    document.addEventListener('DOMContentLoaded', function() {
      var elem = document.querySelectorAll('.tabs');
      M.Tabs.init(elem, {});		          
    });

    M.AutoInit();
  },
  methods:{
    //? ................................................
    clickImagen(e){

      this.imagen=e.target.files[0];

      this.aplicacionRegistro.imagen= '/imgAplicaciones/' + this.imagen.name;

      this.iniciando=false;
      this.imagenHTML= this.imagen;
    },
    clickBroshure(e){

        this.broshure=e.target.files[0];

        this.aplicacionRegistro.broshure= '/cvsAplicaciones/' + this.broshure.name;

        this.iniciandoB=false;
        this.broshureHTML= this.broshure;
    },
    
    //? ................................................
    enfoqueNombre(){

      //? Permite direccionar el cursor al campo cuyo ID='inputNombre'
      Vue.nextTick().then(()=> this.$refs.inputNombre.focus());
    },

    //? ................................................
    cargueServiciosGen(){

      this.$store.commit('cargue_ServiciosGen');
    },     
    cargueServiciosEsp(){

      this.$store.commit('cargue_ServiciosEsp');
    },
    //? ................................................
    aplicar(registro){

      this.bAplicar= true;

      this.seleccionado.nombre=registro.nombre;
      this.seleccionado.descripcion=registro.descripcion;
      this.seleccionado.vigencia=registro.vigencia;

      this.enfoqueNombre();      
    },

    //? ................................................
    grabarAplicacion(){
      
      //* Confirma el ingreso
      Swal.fire({

          title: '¿ Seguro de grabar la Aplicación ? ',
          showCancelButton: true,
          confirmButtonColor: '#008000',
          cancelButtonColor: '#C4000D',
          confirmButtonText: 'Grabar'
      })
      .then((result) => {

          //* Si confirma el ingreso entonces
          if (result.value) {

              let tNombre= this.aplicacionRegistro.nombre;
              let tGracias= 'Gracias por aplicar con nosotros ' + tNombre + '. Nos estaremos comunicando con usted.'

              this.aplicacionRegistro.puesto= this.seleccionado.nombre;

              this.imgYdatos={};
              this.imgYdatos={
                  save_imagen: this.imagen,
                  save_datos: this.aplicacionRegistro,
                  save_broshure: this.broshure
              };

              //* Llamado asyncrono para grabar la imagen del Suscriptor.
              this.$store.dispatch('grabar_imgAplicacion',this.imgYdatos);

              //* Muestra mensaje de "registro grabado"
              const Toast = Swal.mixin({

                  timerProgressBar: true,
              });
              Toast.fire({
                  position: 'center',
                  icon: 'success',
                  title: tGracias,
                  showConfirmButton: false,
                  timer: 3000
              })

              //* Limpia datos después de grabar
              this.limpiarDatosDingreso();

          } //* if (result.value)
      })
    },
      limpiarDatosDingreso(){

        this.aplicacionRegistro= {   
          nombre: '',
          whatsApp: '',
          email: '',
          estatus: 'APLICANDO',
  
          imagen: '',
          imagenRuta: '',
          broshure: '',
          broshureRuta: '',
  
          senalado: '',
        };

        this.bAplicar= false;
    },
    cancelarIngreso(){

      //? Inicializa de nuevo la colección de datos
      this.limpiarDatosDingreso();      
  },    
  },
} 
