//* @\store\modules/especificos\mod_serviciosEsp.js
//* 
//* Vuex para los Servicios Específicos
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Noviembre, 2022

import store from '@/store'

import {

    storage,
    dbServiciosGen,
    dbServiciosEsp,
    dbAplicaciones,    
} from '@/store/myFirebaseApp'
  
const refImagenes = storage.ref();

//? El objeto que tendrá el catálogo de Proveedores.
const st_ServiciosGen = [];
const st_ServiciosEsp = [];
  
//? Las mutaciones para el catálogo de Proveedores.
const mutations = {

    cargue_ServiciosGen: (state) => {

        dbServiciosGen
        .on('value', snapshot => 
            mutations.obtieneServiciosGen(state, snapshot.val() )
        );  
    },
    obtieneServiciosGen: (state,losRegistros) => {

        state.st_ServiciosGen = [];

        for (let key in losRegistros) {

            state.st_ServiciosGen.push({
                llave:        key,

                nombre: losRegistros[key].nombre,             
                descripcion: losRegistros[key].descripcion,             

                senalado: 	false,
            });
        } //* for
    }, //* obtieneEvaluacionesPol

    //? .......................................................................
    cargue_ServiciosEsp: (state) => {

        dbServiciosEsp
        .on('value', snapshot => 
            mutations.obtieneServiciosEsp(state, snapshot.val() )
        );  
    },
    obtieneServiciosEsp: (state,losRegistros) => {

        state.st_ServiciosEsp = [];

        for (let key in losRegistros) {

            state.st_ServiciosEsp.push({
                llave:        key,

                nombre:     losRegistros[key].nombre,             
                descripcion:losRegistros[key].descripcion,             
                vigencia:   losRegistros[key].vigencia,
                estatus:   losRegistros[key].estatus,

                senalado: 	false,
            });
        } //* for
    }, //* obtieneEvaluacionesPol

    //? .......................................................................
    grabar_Aplicacion: (state,elRegistro) =>{

        dbAplicaciones.push(elRegistro);    
    },

    grabar_imgAplicacion(state,laInfo){

        //* referencia a la carpeta donde quedará grabada la imagen
        const refImagen= refImagenes.child('imgAplicaciones/' + laInfo.save_imagen.name);
        const refBroshure= refImagenes.child('cvsAplicaciones/' + laInfo.save_broshure.name);

        //* graba la IMAGEN 
        refImagen.put(laInfo.save_imagen)
        .then( (e) => {

            //* Obtiene la url de la imagen en firestore
            refImagenes.child('imgAplicaciones/' + laInfo.save_imagen.name)
            .getDownloadURL()
            .then( (url) =>{

                laInfo.save_datos.imagenRuta= url;

                //* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
                //* graba el BROSHURE
                refBroshure.put(laInfo.save_broshure)
                .then( (e) => {

                    //* Obtiene la url de la imagen en firestore
                    refImagenes.child('cvsAplicaciones/' + laInfo.save_broshure.name)
                    .getDownloadURL()
                    .then( (urlB) =>{

                        laInfo.save_datos.broshureRuta= urlB; 

                        //* Graba los datos del Suscriptor
                        store.dispatch('grabar_Aplicacion', laInfo.save_datos);                        
                    })
                })
                //* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
            })  
        });
    },    
};

const actions = {
    async grabar_Aplicacion(context,elRegistro){
        await context.commit('grabar_Aplicacion',elRegistro);
    },
    
    async grabar_imgAplicacion(context,elRegistro){
        await context.commit('grabar_imgAplicacion',elRegistro);
    },    
};

//? Los getters para el catálogo de Proveedores.
const getters= {

    losServiciosGen: (state) =>{

        return state.st_ServiciosGen;
    },
    losServiciosEsp: (state) =>{

        return state.st_ServiciosEsp;
    }
}
  
  //? ......................................................
export default {
    state:{
        st_ServiciosGen,
        st_ServiciosEsp, 
    },
    actions,
    mutations,
    getters,
}
  
  
  