//* @\store\modules/generales\mod_Evaluaciones.js
//* 
//* Script para las pestañas que tendrán los productos
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Octubre, 2021

import {

    dbEvaluaciones,
} from '@/store/myFirebaseApp'
  

//? El objeto que tendrá el catálogo de Proveedores.
const st_Evaluaciones = [];
  
//? Las mutaciones para el catálogo de Proveedores.
const mutations = {

    cargue_Evaluaciones: (state) => {

        dbEvaluaciones
        .on('value', snapshot => 
            mutations.obtieneEvaluaciones(state, snapshot.val() )
        );  
    },
    obtieneEvaluaciones: (state,losRegistros) => {

        state.st_Evaluaciones = [];

        for (let key in losRegistros) {

            state.st_Evaluaciones.push({

                llave:        key,

                textoPolimetro: losRegistros[key].textoPolimetro,
                textoPsicometricos: losRegistros[key].textoPsicometricos,

                senalado: 	false,
            });
        } //* for
    }, //* obtieneEvaluaciones  
};
  
const actions = {
 
};

//? Los getters para el catálogo de Proveedores.
const getters= {

    lasEvaluaciones: (state) =>{

        return state.st_Evaluaciones;
    }
}
  
  //? ......................................................
export default {
    state:{
        st_Evaluaciones,
    },
    actions,
    mutations,
    getters,
}
  
  
  