import Vue          from 'vue'

import VueRouter    from 'vue-router'
import Home         from '../views/Home.vue'
import Servicios    from '../views/Servicios.vue'
import Evaluaciones from '../views/Evaluaciones.vue'

import Contactanos from '../views/Contactenos.vue'
import Servigua    from '../views/Servigua.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/servicios',
    name: 'Servicios',
    component: Servicios
  },
  {
    path: '/evaluaciones',
    name: 'Evaluaciones',
    component: Evaluaciones
  },
  {
    path: '/contactanos',
    name: 'Contactanos',
    component: Contactanos
  },
  {
    path: '/servigua',
    name: 'Servigua',
    component: Servigua
  },  
]

const router = new VueRouter({
  routes
})

export default router
