//* @/views/contactenos.js
//*
//* Script del componente @/views/contactenos.vue.
//*
//* Desarrollado por Juan Carlos Jocop
//* Octubre, 2022

import { mapGetters } from 'vuex'

export default {
    name: 'contactanos',
    data(){
        return {
        }
    },
    computed: {
        ...mapGetters(['parametrosOtros']),
        filtrar(){
			return this.parametrosOtros
                .filter((registro) => {

                    return registro;
                })
        },        
    },
    created() {

        this.cargarOtro();

        M.AutoInit();
    },
    methods: {
        cargarOtro(){

            //* Carga la información de la página de otro
            this.$store.dispatch('cargue_Otros');    
        },        
    }
}