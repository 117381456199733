//* @\store\myFirebaseApp.js
//*
//* Script para acceder a la configuración del proyecto FB que se usará en 
//* el software. 
//*   
//* Desarrollado por Juan Carlos Jocop.
//* Agosto, 2021

import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCJS3YnBHt88T3L09OlD8YAREmeMy92gAM",
  authDomain: "servinsawp.firebaseapp.com",
  databaseURL: "https://servinsawp-default-rtdb.firebaseio.com",
  projectId: "servinsawp",
  storageBucket: "servinsawp.appspot.com",
  messagingSenderId: "783383286921",
  appId: "1:783383286921:web:3db7ec93e3cc2d2da5a258"
};

//? Initialize Firebasenom run
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

//? Direccionamiento al almacenamiento de imagenes
export const storage = firebase.storage();

/** //? Direccionamiento a Firebase */
export const db = firebase.database(); // Accedemos a la Base de datos

//? ............................................................
//? Usuarios, bitácora y datos de configuración */
export const ruta             =  "/";


//? :-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-
//? Inicio */
export const rutaInicio=  '/generales/inicio';
export const dbInicio=    db.ref(rutaInicio);

//? Servicios Generales */
export const rutaServiciosG=  '/especificos/serviciosG';
export const dbServiciosG=    db.ref(rutaServiciosG);

//? Servicios Específicos */
export const rutaServiciosGen=  '/especificos/serviciosgen';
export const dbServiciosGen=    db.ref(rutaServiciosGen);

export const rutaServiciosEsp=  '/especificos/serviciosesp';
export const dbServiciosEsp=    db.ref(rutaServiciosEsp);

//? Aplicaciones a las plazas
export const rutaAplicaciones=  '/especificos/aplicaciones';
export const dbAplicaciones=    db.ref(rutaAplicaciones);

//? Evaluaciones */
export const rutaEvaluaciones=  '/especificos/evaluaciones';
export const dbEvaluaciones=    db.ref(rutaEvaluaciones);

//? Otros Parámetros */
export const rutaOtros=  '/generales/otros';
export const dbOtros=    db.ref(rutaOtros);

//? :-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-

