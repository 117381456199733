//* @\store\index.js
//* 
//* Script del vuex (almacén de datos).
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Octubre, 2021

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

//? ................................................................................................
const mutations= {}

//? Módulos de Parámetros Generales
import moduloInicio       from './modules/generales/mod_inicio.js'
import moduloServicios    from './modules/especificos/mod_servicios.js'

import moduloOtros        from './modules/generales/mod_otros.js'

import moduloEvaluaciones from './modules/especificos/mod_evaluaciones.js'

export default new Vuex.Store({
  modules:{

    moduloInicio,
    moduloServicios,

    moduloOtros,

    moduloEvaluaciones,
  },
  state:{

    //? Para usar las funciones de Javascript que están en @/store/funciones.js
    st_lib: "hola que tal",                 //* Este es necesario para acceder a las funciones de node.js
    st_lib: require("@/store/funciones.js"),
    formatos: require("@/store/formatos.js"),
    paginear: require("@/store/paginacion.js"),
  },
  getters:{
  },
  mutations,
})
