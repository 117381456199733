<!-- //* ./@/app_menutablets.Vue
<!--//*
<!--//* Componente para la navegación y ruteo en dispositivos móviles
<!--//*
<!--//* Desarrollado por Juan Carlos Jocop.
<!--//* Noviembre, 2022 -->

<template id="menutablets">
    <div style="cursor:pointer">
        <ul class="sidenav left" id="mobile-demo">

            <br>
            <!--//? 1. INICIO -->
            <div class="col s12 m2">
                <b>
                    <router-link
                        to="/"
                        tag="li"
                        exact>
                            INICIO
                    </router-link>
                </b>
            </div>

            <div class="col s12 m2">
                <b>
                    <router-link
                        to="/Servicios"
                        tag="li"
                        exact>
                            SERVICIOS
                    </router-link>
                </b>
            </div>
            
            <div class="col s12 m2">
                <b>
                    <router-link
                        to="/Evaluaciones"
                        tag="li"
                        exact>
                            EVALUACIONES
                    </router-link>
                </b>
            </div>         
        </ul>
    </div>
</template>

<script>
     export default {
        name: 'menutablets',
     }     
</script>

