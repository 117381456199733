//* ./@/views/Evaluaciones.js
//*
//* Script del componente ./@/views/Evaluaciones.vue.
//*
//* Desarrollado por Juan Carlos Jocop
//* Noviembre, 2022

import { mapGetters } from 'vuex'

export default {
  name: 'Home',

  data () {
    return {} 
  }, 
  computed:{
    ...mapGetters(['lasEvaluaciones']),      
    filtrarEvaluaciones(){
			return this.lasEvaluaciones
          .filter((registro) => {

          return registro;
      })
    },
  },  
  mounted(){

    this.cargarEvaluaciones();

    M.AutoInit();
  },
  methods:{    
    cargarEvaluaciones(){

      //* Carga la información de la página de inicio
      this.$store.commit('cargue_Evaluaciones');    
    },
  },
} 
