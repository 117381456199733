//* @\store\librerías.js
//* 
//* Script con funciones para manejar fechas, y formatos numéricos.
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Agosto, 2021

//? Separa en comas un valor numérico cuando ya incluye una cantidad 
//? en miles o millones.
function separadorMiles(amount){

    if (amount !== '' && amount !== undefined && 
        amount !== 0  && amount !== '0' && 
        amount !== null) {

        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        if (amount === null){
            return null
        } else {
            return amount;
        }
    }     
}
//? Quita las comas que hubieran en un valor numérico
function quitaComas(texto,busca,reemplaza){

    while (texto.toString().indexOf(busca) != -1) {

        texto = texto.toString().replace(busca,reemplaza);
    }
    return texto;
}


/* //? .......................................................................................... */
/* //? Formato nn.dd (con decimales) */
function Decimales(value) {

    let val = (value / 1).toFixed(2).replace(',', '.')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

/* //? Formato nn (sin decimales) */
function Entero(value) {

    let val = (value / 1).toFixed(0).replace(',', '.')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

//? Returna la fecha del día en formato "aaaa-mm-dd" 
function fechaGuion(){

    /* //* Obtiene la fecha del día concurrente */
    let fechaT = new Date();

    /* //* Día, mes y año en formato numérico */
    let dia   = fechaT.getDate();
    let mes   = fechaT.getMonth() + 1;
    let anio  = fechaT.getFullYear();

    /* //* Día, mes y año en formato string */
    dia = dia <= 9 ? "0" + dia : dia;
    mes = mes <= 9 ? "0" + mes : mes;

    return `${anio}-${mes}-${dia}`;
}

//? Regresa fecha del día en formato "dd/mm/aaaa"
function fechaDiagonal(fechaT){

    /* //* Día, mes y año en formato numérico */
    let dia = fechaT.getDate() + 1;
    let mes = fechaT.getMonth() + 1;
    let anio = fechaT.getFullYear();

    /* //* Día, mes y año en formato string */
    dia = dia <= 9 ? "0" + dia : dia;
    mes = mes <= 9 ? "0" + mes : mes;

    return `${dia}/${mes}/${anio}`;
}

//? Regresa la hora y minutos del día en formato "hh:mm"
function Hora(fechaT){
    
    const hora = fechaT.getHours();
    const minutos = fechaT.getMinutes();

    return `${hora}:${minutos}`;     
}

export {
    separadorMiles, quitaComas,
    Entero, Decimales, 
    fechaGuion, fechaDiagonal, Hora,
}