//* @\store\paginacion.js
//* 
//* Script con funciones para para el pagineo en los 
//* paneles de datos de cada opción.
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Agosto, 2021

function pagina(objeto,actual,regxpag){
 
    //? Ejemplo:
    //? desde = (2 * 15) - 15 = 30-15 = 15
    //? hasta = 2 * 15 = 30 

    let desde=     (actual * regxpag) - regxpag;
    let hasta=     actual * regxpag;

    return objeto.slice(desde,hasta);
}

function incremente(regxpag){

    if (regxpag<=10){

        regxpag= regxpag + 5
    }

    return regxpag;
}

function decremente(regxpag){

    if (regxpag>5){
        regxpag= regxpag - 5
    }

    return regxpag;
}		

function residuo(laUltimaPagina,elObjeto,regxpag){

    //? obtiene el residuo
    let residuo= elObjeto.length % regxpag;

    //? si hay residuo, incrementa en 1 ultima página obtenida
    if (residuo > 0) {
        laUltimaPagina = laUltimaPagina + 1;
    }

    return laUltimaPagina;
}

//? Si la página concurrente <= a la última página, entonces continua el incremento
function proxima(ultima,pagActual){

    if (pagActual + 1 <= ultima){
        pagActual = pagActual + 1;
    };

    return pagActual;
}

//? Si la página actual aún > la 1a. página, continúa decrementando el decremento
function antes(pagActual){

    if (pagActual - 1 >= 1){
        pagActual = pagActual - 1;
    }

    return pagActual;
}

export { pagina, incremente, decremente, residuo, proxima, antes };