//* @\store\modules/generales\mod_Otro.js
//* 
//* Script para las pestañas que tendrán los productos
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Octubre, 2021

import {

    dbOtros,
} from '@/store/myFirebaseApp'
  
//? El objeto que tendrá el catálogo de Proveedores.
const st_Otros = [];
  
//? Las mutaciones para el catálogo de Proveedores.
const mutations = {

    cargue_Otros: (state) => {

        dbOtros
        .on('value', snapshot => 
            mutations.obtieneOtros(state, snapshot.val() )
        );  
    },
    obtieneOtros: (state,losRegistros) => {

        state.st_Otros = [];

        for (let key in losRegistros) {

            state.st_Otros.push({

                llave:        key,

                nombre: losRegistros[key].nombre,
                direccion: losRegistros[key].direccion,
                telefono: losRegistros[key].telefono,
                whatsApp: losRegistros[key].whatsApp,
                whatsAppPage: losRegistros[key].whatsAppPage,
                correo: losRegistros[key].correo,

                senalado: 	false,
            });
        } //* for
    }, //* obtieneOtro
   
};
  
const actions = {
    
    async cargue_Otros(context,elRegistro){
        await context.commit('cargue_Otros',elRegistro);
    },

};

//? Los getters para el catálogo de Proveedores.
const getters= {

    parametrosOtros: (state) =>{

        return state.st_Otros;
    }
}
  
  //? ......................................................
export default {
    state:{
        st_Otros,
    },
    actions,
    mutations,
    getters,
}
  
  
  