//* @/components/iconosWyC.js
//* 
//* Script del componente @/components/iconosWyC.vue. 
//*
//* Desarrollado por Juan Carlos Jocop.
//* Octubre, 2022

import { mapGetters } from 'vuex'

import Vue from 'vue'
import VTitle from 'v-title';
Vue.use(VTitle);

export default{
    name:'iconosWyC',
    data(){
        return{
            elWhatsApp: '',
            mensaje: 'Contáctenos'
        }
    },
    computed: {
        ...mapGetters(['parametrosOtros']),
        filtrar(){
			return this.parametrosOtros
                .filter((registro) => {

                    this.elWhatsApp= registro.whatsApp;

                    return registro;
                })
        },         
        numeroWhats(){
            
            return 'https://wa.me/'+this.elWhatsApp+'?text='
        },
        facebook(){
            return 'https://www.facebook.com/Servinsagt'
        },
        instagram(){
            return 'https://www.instagram.com/servin_sa/'
        },
    },
    created(){

        this.cargarOtro();

        // this.cargarOtrosParametros();
    },
    methods: {
        verCarretilla(){
            this.$router.push('/carretilla');
        },
        // cargarOtrosParametros(){
        //     this.$store.commit('cargue_otrosParametros')
        // },
        verContactenos(){
            this.$router.push('/contactanos');
        },

        cargarOtro(){

            //* Carga la información de la página de otro
            this.$store.dispatch('cargue_Otros');    
        },          
    },
}