//* @\store\modules\generales\mod_Inicio.js
//* @\store\catalogos\mod_Inicio.js
//* 
//* Script para las pestañas que tendrán los productos
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Octubre, 2022

import {

    storage,
    dbInicio,
} from '@/store/myFirebaseApp'

const refImagenes = storage.ref();

//? El objeto que tendrá el catálogo de Proveedores.
const st_Inicio = [];
  
//? Las mutaciones para el catálogo de Proveedores.
const mutations = {

    cargue_Inicio: (state) => {

        dbInicio
        .on('value', snapshot => 
            mutations.obtieneInicio(state, snapshot.val() )
        );  
    },
    obtieneInicio: (state,losRegistros) => {

        state.st_Inicio = [];

        for (let key in losRegistros) {

            state.st_Inicio.push({

                llave:        key,

                textoInicio: losRegistros[key].textoInicio,

                textoMision: losRegistros[key].textoMision,
                textoVision: losRegistros[key].textoVision,
                textoValores: losRegistros[key].textoValores,

                senalado: 	false,
            });
        } //* for

    }, //* obtieneInicio
   
};
  
const actions = {

    cargue_Inicio(context){
        context.commit('cargue_Inicio');
    },

    grabar_Inicio(context,elRegistro){
        context.commit('grabar_Inicio',elRegistro);
    },
    actualizar_Inicio(context,elRegistro){
        context.commit('actualizar_Inicio',elRegistro);
    },  
};

//? Los getters para el catálogo de Proveedores.
const getters= {

    parametrosInicio: (state) =>{

        return state.st_Inicio;
    }
}
  
  //? ......................................................
export default {
    state:{
        st_Inicio,
    },
    actions,
    mutations,
    getters,
}
  
  
  