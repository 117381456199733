//* @\store\funciones.js
//* 
//* Script con funciones para manejar fechas, y formatos numéricos.
//* 
//* Desarrollado por Juan Carlos Jocop.
//* Agosto, 2021

function thousandSeprator(amount){
     if (amount !== '' && amount !== undefined && 
     amount !== 0  && amount !== '0' && 
     amount !== null) {

     return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
 } else {
     if (amount === null){
         return null
     } else {
         return amount;
     }
 }     
}

/* //? .......................................................................................... */
/* //? Formato nn.dd (con decimales) */
function formatPriceR(value) {
     let val = (value / 1).toFixed(2).replace(',', '.')
     return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

/* //? Formato nn (sin decimales) */
function formatPriceE(value) {
     let val = (value / 1).toFixed(0).replace(',', '.')
     return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

/** //? Returna la fecha del día en formato string */
function getFechas(){

     /* //* Obtiene la fecha del día concurrente */
     let fechaT = new Date();

     /* //* Día, mes y año en formato numérico */
     let dia   = fechaT.getDate();
     let mes   = fechaT.getMonth() + 1;
     let anio  = fechaT.getFullYear();

     /* //* Día, mes y año en formato string */
     dia = dia <= 9 ? "0" + dia : dia;
     mes = mes <= 9 ? "0" + mes : mes;

     return `${anio}-${mes}-${dia}`;
}

exports.fechaHoy = function fechaHoy(fechaT){

     /* //* Día, mes y año en formato numérico */
     let dia = fechaT.getDate() +1;
     let mes = fechaT.getMonth() + 1;
     let anio = fechaT.getFullYear();

     /* //* Día, mes y año en formato string */
     dia = dia <= 9 ? "0" + dia : dia;
     mes = mes <= 9 ? "0" + mes : mes;

     return `${dia}/${mes}/${anio}`;
}

function getHora(fechaT){
     
     const hora = fechaT.getHours();
     const minutos = fechaT.getMinutes();

     return `${hora}:${minutos}`;     
}


/* //? .......................................................................................... */
/* //? Exportación de las funciones */
module.exports.formatPriceR=  formatPriceR;
module.exports.formatPriceE=  formatPriceE;
module.exports.getFechas=     getFechas;
module.exports.getHora=       getHora;
module.exports.fechaHoy=      this.fechaHoy;

module.exports.thousandSeprator=thousandSeprator;
