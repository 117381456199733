//* ./@/views/Home.js
//*
//* Script del componente ./@/views/Home.vue.
//*
//* Desarrollado por Juan Carlos Jocop
//* Noviembre, 2022

import { mapGetters } from 'vuex'

export default {
  name: 'Home',

  data () {
    return {
      inicio: {},

      mostrarLogo: true,

      //? Variables para el TIMER
      countDownDate: new Date("oct 29 2018 00:00:00").getTime(),
      distance: 0,
      seconds: 0,         
    } 
  }, 
  computed:{
    ...mapGetters(['parametrosInicio']), 
    filtrar(){
			return this.parametrosInicio
          .filter((registro) => {

          this.inicio.textoInicio= registro.textoInicio;
          this.inicio.textoMision= registro.textoMision;
          this.inicio.textoVision= registro.textoVision;
          this.inicio.textoValores= registro.textoValores;

          return registro;
      })
    },
  },  
  mounted(){

    //? Luego activa el reloj para refrescar la vista si en caso los
    //? roles de estas opciones fueron cambiadas para el usuario en sesión.
    this.reloj();

    this.cargarInicio();

    M.AutoInit();
  },
  methods:{    
    cargarInicio(){

      //* Carga la información de la página de inicio
      this.$store.commit('cargue_Inicio');    
    },
    reloj(){
            
      var vm = this
      var now = null;

      setInterval( () => {

          now= new Date().getTime();

          vm.distance = vm.countDownDate - now;
          vm.seconds = Math.floor((vm.distance % (1000 * 60)) / 1000);

          //* A cada 3 segundos se vuelve a cargar los MODULOS 
          if (vm.seconds % 15 === 0){

              vm.ocultarLogo();
          }    
      }, 1000);
    },
    ocultarLogo(){
      this.mostrarLogo=false;
    },
  },
} 
