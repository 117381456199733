//* .\@\app_navbarvue.js
//* 
//* Script del componente .\@\app_navbarvue.vue 
//*
//* Desarrollado por Lucía De León Madrid.
//* Agosto, 2021

import menutablets  from '@/app_menutablets.vue';

import {mapState, mapGetters } from 'vuex'

export default {

    name: 'navbar',
    data(){
        return {
            indiceModulo: 0,
        }
    },
    components: {
        menutablets,
    },
    computed: {
        ...mapState(['st_Modulos']),
        ...mapGetters(['losModulos','elModuloSeleccionado']),
    },
}